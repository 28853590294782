import React from 'react'
import { useTranslation } from 'react-i18next'

export default function Footer() {
  const { t, i18n } = useTranslation()
  return (
   <>
  

   <div className="container">
  <footer className="d-flex flex-wrap justify-content-between align-items-center py-3 my-4 border-top">
    <div className="col-md-4 d-flex align-items-center">
      <a href="/" className="mb-3 me-2 mb-md-0 text-muted text-decoration-none lh-1">
        
      </a>
      <span className="mb-3 mb-md-0 text-muted">© 2023 Cie Contactors</span>
    
    </div>

    <ul className="nav col-md-4 justify-content-center list-unstyled d-flex">
      <li className="ms-3"><a className="text-muted" href="#"><i className="fa-brands fa-twitter"></i></a></li>
      <li className="ms-3"><a className="text-muted" href="#"><i className="fa-brands fa-instagram"></i></a></li>
      <li className="ms-3"><a className="text-muted" href="#"><i className="fa-brands fa-facebook-f"></i></a></li>
    </ul>
    
    {/* <ul className="nav col-md-4 justify-content-end list-unstyled d-flex">
      <li className="ms-3"><div className=' text-center'>
                    <span>01231512151215</span>
                    <p>{t("Ahmed")}</p>

                </div></li>
      <li className="ms-3"><div className=' text-center'>
                    <span>01231512151215</span>
                    <p>{t("mohamed")}</p>

                </div></li>

    </ul> */}
  </footer>
</div>
    

   </>

  )
}
